import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const FAQItem = styled(motion.div)`
  margin-bottom: 1.5rem;
`;

const Question = styled.h3`
  margin-bottom: 0.5rem;
  color: #333;
`;

const Answer = styled.p`
  color: #666;
`;

const faqItems = [
  { 
    question: "What types of websites do you build?",
    answer: "We specialize in custom static websites, WordPress development, and web applications. Whether you need a simple brochure site or a complex e-commerce platform, we've got you covered."
  },
  {
    question: "How long does it take to build a website?",
    answer: "The timeline varies depending on the complexity of the project. A simple static website might take 2-4 weeks, while a more complex web application could take 8-12 weeks or more. We'll provide a detailed timeline during our initial consultation."
  },
  {
    question: "Do you offer ongoing maintenance and support?",
    answer: "Yes, we offer both managed and unmanaged hosting options. Our managed hosting includes regular updates, security monitoring, and technical support."
  },
  // Add more FAQ items as needed
];

const FAQ = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Frequently Asked Questions - LLT Solutions</title>
        <meta name="description" content="Find answers to common questions about our web development and SEO services. Learn more about our process, pricing, and support options." />
      </Helmet>
      <h1>Frequently Asked Questions</h1>
      {faqItems.map((item, index) => (
        <FAQItem
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
        >
          <Question>{item.question}</Question>
          <Answer>{item.answer}</Answer>
        </FAQItem>
      ))}
    </PageContainer>
  );
};

export default FAQ;