import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const TestimonialContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`;

const TestimonialContent = styled(motion.div)`
  background-color: #f0f4f8;
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const Author = styled.p`
  font-weight: bold;
  margin-top: 1rem;
`;

const testimonials = [
  { id: 1, content: "LLT Solutions transformed our online presence. Their expertise in web development and SEO has significantly boosted our traffic and conversions.", author: "Jane Doe, CEO of XYZ Company" },
  { id: 2, content: "Working with LLT Solutions was a game-changer for our business. Their custom web application streamlined our operations and improved customer satisfaction.", author: "John Smith, Founder of ABC Startup" },
  // Add more testimonials
];

const TestimonialSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      }, 5000); // Change testimonial every 5 seconds
  
      return () => clearInterval(timer);
    }, []);
  
    return (
      <TestimonialContainer>
        <AnimatePresence mode="wait">
          <TestimonialContent
            key={testimonials[currentIndex].id}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <p>"{testimonials[currentIndex].content}"</p>
            <Author>{testimonials[currentIndex].author}</Author>
          </TestimonialContent>
        </AnimatePresence>
      </TestimonialContainer>
    );
  };
  
  export default TestimonialSlider;