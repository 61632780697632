import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Button from '../components/Button';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
`;

const ServiceSection = styled(motion.div)`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const ServiceTitle = styled.h2`
  font-size: 1.8rem;
  color: #2d3748;
  margin-bottom: 1rem;
`;

const ServicesOverview = () => {
  return (
    <>
      <Helmet>
        <title>Our Services - LLT Solutions</title>
        <meta name="description" content="Explore LLT Solutions' range of web development and SEO services, including static websites, WordPress development, custom web applications, and our upcoming SEOKeyword App." />
        <meta name="keywords" content="web development services, SEO services, static websites, WordPress development, custom web applications, SEOKeyword App" />
      </Helmet>
      <PageContainer>
        <Title
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Our Web Services
        </Title>

        <ServiceSection
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <ServiceTitle>Static Websites</ServiceTitle>
          <p>
            Fast, secure, and cost-effective web solutions perfect for businesses 
            looking to establish a strong online presence. Ideal for service-based 
            businesses and informational websites.
          </p>
          <Button as={Link} to="/services/static-websites">Learn More</Button>
        </ServiceSection>

        <ServiceSection
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <ServiceTitle>WordPress Development</ServiceTitle>
          <p>
            Harness the power of the world's most popular CMS. We create custom 
            WordPress solutions that are scalable, secure, and easy to manage.
          </p>
          <Button as={Link} to="/services/wordpress-websites">Learn More</Button>
        </ServiceSection>

        <ServiceSection
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <ServiceTitle>Custom Web Applications</ServiceTitle>
          <p>
            Tailor-made web solutions to address your unique business challenges. 
            We build robust, scalable, and user-friendly web applications.
          </p>
          <Button as={Link} to="/services/web-development">Learn More</Button>
        </ServiceSection>

        <ServiceSection
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <ServiceTitle>SEO Services & SEOKeyword App</ServiceTitle>
          <p>
            Boost your online visibility with our comprehensive SEO services. 
            Plus, get ready for our upcoming SEOKeyword App, designed to revolutionize 
            your keyword research and content optimization strategy.
          </p>
          <Button as={Link} to="/seo-services">Learn More</Button>
        </ServiceSection>
      </PageContainer>
    </>
  );
};

export default ServicesOverview;