import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const PricingContainer = styled.div`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const PricingCard = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const PlanName = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Price = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #888;
  font-size: 1.2rem;
`;

const Savings = styled.div`
  color: #48bb78;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
  flex-grow: 1;
`;

const Feature = styled.li`
  margin-bottom: 0.5rem;
  &:before {
    content: "✓";
    color: #48bb78;
    margin-right: 0.5rem;
  }
`;

const CTAButton = styled(Link)`
  background-color: #4299e1;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3182ce;
  }
`;

const AnimalFundraisingPricing = () => {
  const pricingPlans = [
    {
      name: "Standard",
      originalPrice: 2299,
      discountedPrice: 1000,
      features: [
        "Custom 5-page website",
        "Mobile-First Design",
        "Fully Responsive",
        "SEO Optimization",
        "Google Analytics Setup",
        "Search Engine Submit",
        "Custom Email Setup",
      ],
    },
    {
      name: "Premium",
      originalPrice: 2599,
      discountedPrice: 1200,
      features: [
        "Everything in Standard",
        "Blog Setup",
        "Newsletter Integration",
        "Appointment Scheduling",
      ],
    },
    {
      name: "E-Commerce",
      originalPrice: 3999,
      discountedPrice: 1500,
      features: [
        "Everything in Premium",
        "Online Store Setup",
        "Payment Gateway Integration",
        "Product Management System",
      ],
    },
  ];

  return (
    <PricingContainer>
      <h2>Special Animal Fundraising Offers</h2>
      <p>Choose a plan and help us support animal welfare!</p>
      <PricingGrid>
        {pricingPlans.map((plan) => (
          <PricingCard
            key={plan.name}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <PlanName>{plan.name}</PlanName>
            <Price>
              ${plan.discountedPrice}{' '}
              <OriginalPrice>${plan.originalPrice}</OriginalPrice>
            </Price>
            <Savings>
              Save ${plan.originalPrice - plan.discountedPrice}!
            </Savings>
            <FeatureList>
              {plan.features.map((feature, index) => (
                <Feature key={index}>{feature}</Feature>
              ))}
            </FeatureList>
            <CTAButton to="/contact">Get Started</CTAButton>
          </PricingCard>
        ))}
      </PricingGrid>
    </PricingContainer>
  );
};

export default AnimalFundraisingPricing;