// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import ServicesOverview from './pages/ServicesOverview';
import StaticWebsites from './pages/StaticWebsites';
import WordPressWebsites from './pages/WordPressWebsites';
import WebDevelopment from './pages/WebDevelopment';
import SEOOptimization from './pages/SEOOptimization';
import Portfolio from './pages/Portfolio';
import Blog from './pages/Blog';
import BlogCategories from './pages/BlogCategories';
import AnimalFundraising from './pages/AnimalFundraising';
import SEOKeywordApp from './pages/SEOKeywordApp';
import DonatePage from './pages/DonatePage';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';

function App() {
  return (
    <>
      <Header />
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<ServicesOverview />} />
        <Route path="/services/static-websites" element={<StaticWebsites />} />
        <Route path="/services/wordpress-websites" element={<WordPressWebsites />} />
        <Route path="/services/web-development" element={<WebDevelopment />} />
        <Route path="/services/seo-optimization" element={<SEOOptimization />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/categories" element={<BlogCategories />} />
        <Route path="/animal-fundraising" element={<AnimalFundraising />} />
        <Route path="/seo-keyword-app" element={<SEOKeywordApp />} />
        <Route path="/donate" element={<DonatePage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;