import React from 'react';
import { Card, Image, Info } from './styles';

const AnimalCard = ({ animal }) => (
  <Card
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Image src={animal.image} alt={animal.name} />
    <Info>
      <h3>{animal.name}</h3>
      <p>{animal.breed}</p>
    </Info>
  </Card>
);

export default AnimalCard;