import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

const FormContainer = styled.form`
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
`;

const AmountOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const AmountButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.selected ? '#4CAF50' : '#f0f0f0'};
  color: ${props => props.selected ? 'white' : 'black'};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.selected ? '#45a049' : '#e0e0e0'};
  }
`;

const CardElementContainer = styled.div`
  border: 1px solid #e2e8f0;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #e53e3e;
  margin-top: 1rem;
  text-align: center;
`;

const SuccessMessage = styled.div`
  color: #38a169;
  margin-top: 1rem;
  text-align: center;
  font-weight: bold;
`;

const DonationInfo = styled.p`
  margin-bottom: 1rem;
  color: #4a5568;
  text-align: center;
`;

const DonationTracker = styled.div`
  background-color: #f0f4f8;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
`;

const ProgressBar = styled.div`
  background-color: #e2e8f0;
  border-radius: 9999px;
  height: 20px;
  margin-top: 0.5rem;
`;

const Progress = styled.div`
  background-color: #48bb78;
  border-radius: 9999px;
  height: 100%;
  width: ${props => props.percentage}%;
`;

const DonationForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState('');
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [totalDonations, setTotalDonations] = useState(0);
  const goalAmount = 12000;

  useEffect(() => {
    const fetchTotalDonations = async () => {
      try {
        const response = await fetch('/.netlify/functions/get-total-donations');
        const data = await response.json();
        setTotalDonations(data.total);
      } catch (error) {
        console.error('Error fetching total donations:', error);
      }
    };

    fetchTotalDonations();
  }, []);

  const handleAmountSelect = (value) => {
    setSelectedAmount(value);
    setAmount(value.toString());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setErrorMessage('');
    setSuccessMessage('');

    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          email,
          address: {
            line1: address,
            city,
            state,
            postal_code: zip,
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      const sanitizedAmount = DOMPurify.sanitize(amount);
      
      // Process payment using Netlify function
      const response = await fetch('/.netlify/functions/process-donation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          amount: parseFloat(sanitizedAmount) * 100, // Convert to cents
          name,
          email,
          address,
          city,
          state,
          zip,
        }),
      });

      const result = await response.json();
      if (result.error) throw new Error(result.error);

      setSuccessMessage(`Thank you for your generous donation of $${sanitizedAmount}!`);
      setAmount('');
      setSelectedAmount(null);
      setName('');
      setEmail('');
      setAddress('');
      setCity('');
      setState('');
      setZip('');
      cardElement.clear();
      
      // Update total donations
      setTotalDonations(prev => prev + parseFloat(sanitizedAmount));
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <DonationTracker>
        <h3>Donation Progress</h3>
        <p>${totalDonations.toFixed(2)} raised of ${goalAmount.toFixed(2)} goal</p>
        <ProgressBar>
          <Progress percentage={(totalDonations / goalAmount) * 100} />
        </ProgressBar>
      </DonationTracker>

      <DonationInfo>
        Your donation helps pay the medical bills for our animals. Our current goal is $12,000 to cover accrued animal bills.
      </DonationInfo>

      <AmountOptions>
        {[10, 20, 50, 100].map((value) => (
          <AmountButton
            key={value}
            type="button"
            selected={selectedAmount === value}
            onClick={() => handleAmountSelect(value)}
          >
            ${value}
          </AmountButton>
        ))}
      </AmountOptions>

      <FormGroup>
        <Label htmlFor="amount">Other Amount ($)</Label>
        <Input
          id="amount"
          type="number"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
            setSelectedAmount(null);
          }}
          placeholder="Enter custom amount"
          required
          min="1"
          step="1.00"
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="name">Full Name</Label>
        <Input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="address">Address</Label>
        <Input
          id="address"
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="city">City</Label>
        <Input
          id="city"
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="state">State</Label>
        <Input
          id="state"
          type="text"
          value={state}
          onChange={(e) => setState(e.target.value)}
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="zip">ZIP Code</Label>
        <Input
          id="zip"
          type="text"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          required
        />
      </FormGroup>

      <Label htmlFor="card-element">Credit or debit card</Label>
      <CardElementContainer>
        <CardElement 
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </CardElementContainer>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

      <Button type="submit" disabled={isProcessing || !stripe}>
        {isProcessing ? 'Processing...' : 'Donate Now'}
      </Button>
    </FormContainer>
  );
};

export default DonationForm;
