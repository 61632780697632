import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import DonationForm from '../components/DonationForm';
import styled from 'styled-components';

const stripePromise = loadStripe('your_stripe_publishable_key');

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f7fafc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #2d3748;
  text-align: center;
  margin-bottom: 2rem;
`;

const Description = styled.p`
  color: #4a5568;
  text-align: center;
  margin-bottom: 2rem;
`;

function DonatePage() {
  return (
    <PageContainer>
      <Title>Support Our Animal Rescue Mission</Title>
      <Description>
        Your donation helps us provide medical care, food, and shelter for animals in need. 
        Every contribution, no matter the size, makes a difference in an animal's life.
      </Description>
      <Elements stripe={stripePromise}>
        <DonationForm />
      </Elements>
    </PageContainer>
  );
}

export default DonatePage;