// src/pages/WebDevelopment.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  color: #1a202c;
  margin-bottom: 1rem;
`;

const Content = styled(motion.div)`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FeatureItem = styled(motion.li)`
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;

  &::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #48bb78;
  }
`;

const CTAButton = styled(motion(Link))`
  display: inline-block;
  background-color: #4299e1;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3182ce;
  }
`;

const WebDevelopment = () => {
  return (
    <PageContainer>
      <Title
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Custom Web Development
      </Title>
      <Content
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <p>
          Our custom web development services are tailored to meet your unique business needs.
          We use cutting-edge technologies to build robust, scalable, and high-performance web applications.
        </p>
        <h2>What We Offer:</h2>
        <FeatureList>
          {[
            'Full-stack web application development',
            'E-commerce solutions',
            'Progressive Web Apps (PWAs)',
            'API development and integration',
            'Database design and optimization',
            'Cloud-based solutions'
          ].map((feature, index) => (
            <FeatureItem
              key={feature}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              {feature}
            </FeatureItem>
          ))}
        </FeatureList>
        <CTAButton
          to="/contact"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Discuss Your Project
        </CTAButton>
      </Content>
    </PageContainer>
  );
};

export default WebDevelopment;