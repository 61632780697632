import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const HeaderContainer = styled.header`
  background-color: #1a202c;
  padding: 1rem 2rem;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled(Link)`
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const NavLink = styled(motion(Link))`
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  ${DropdownContainer}:hover & {
    display: block;
  }
`;

const DropdownLink = styled(Link)`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const Header = () => {
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const mainLinks = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/about' },
    { name: 'Blog', path: '/blog' },
    { name: 'Donate', path: '/donate' },
    { name: 'Contact', path: '/contact' },
  ];

  const serviceLinks = [
    { name: 'Static Websites', path: '/services/static-websites' },
    { name: 'WordPress', path: '/services/wordpress-websites' },
    { name: 'Web Development', path: '/services/web-development' },
    { name: 'SEO Optimization', path: '/services/seo-optimization' },
  ];

  const dropdownLinks = [
    { name: 'Animal Fundraising', path: '/animal-fundraising' },
    { name: 'SEOKeyword App', path: '/seo-keyword-app' },
    { name: 'Portfolio', path: '/portfolio' },
    { name: 'FAQ', path: '/faq' },
  ];

  return (
    <HeaderContainer>
      <Nav>
        <Logo to="/">LLT Solutions</Logo>
        <NavLinks>
          {mainLinks.map((link) => (
            <NavLink key={link.name} to={link.path}>
              {link.name}
            </NavLink>
          ))}
          <DropdownContainer 
            onMouseEnter={() => setIsServicesOpen(true)}
            onMouseLeave={() => setIsServicesOpen(false)}
          >
            <NavLink as="span">Services ▼</NavLink>
            {isServicesOpen && (
              <DropdownContent>
                {serviceLinks.map((link) => (
                  <DropdownLink key={link.name} to={link.path}>
                    {link.name}
                  </DropdownLink>
                ))}
              </DropdownContent>
            )}
          </DropdownContainer>
          <DropdownContainer>
            <NavLink as="span">More ▼</NavLink>
            <DropdownContent>
              {dropdownLinks.map((link) => (
                <DropdownLink key={link.name} to={link.path}>
                  {link.name}
                </DropdownLink>
              ))}
            </DropdownContent>
          </DropdownContainer>
        </NavLinks>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;