import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const PortfolioItem = styled(motion.div)`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PortfolioImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const PortfolioContent = styled.div`
  padding: 1rem;
`;

const portfolioItems = [
  { id: 1, title: 'E-commerce Site', image: '/path/to/image1.jpg', description: 'Custom e-commerce solution for a local boutique.' },
  { id: 2, title: 'Non-Profit Website', image: '/path/to/image2.jpg', description: 'Responsive website for a local animal shelter.' },
  { id: 3, title: 'Tech Startup Landing Page', image: '/path/to/image3.jpg', description: 'High-converting landing page for a SaaS product.' },
  // Add more portfolio items as needed
];

const Portfolio = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Our Portfolio - LLT Solutions</title>
        <meta name="description" content="Explore our portfolio of web development projects. See how we've helped businesses and organizations establish a strong online presence." />
      </Helmet>
      <h1>Our Portfolio</h1>
      <p>Here are some of the projects we've worked on. Each represents our commitment to quality, creativity, and delivering results for our clients.</p>
      <PortfolioGrid>
        {portfolioItems.map((item) => (
          <PortfolioItem
            key={item.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <PortfolioImage src={item.image} alt={item.title} />
            <PortfolioContent>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </PortfolioContent>
          </PortfolioItem>
        ))}
      </PortfolioGrid>
    </PageContainer>
  );
};

export default Portfolio;