import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const About = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>About LLT Solutions - Your Web Development Partner</title>
        <meta name="description" content="Learn about LLT Solutions, our mission, and our commitment to delivering high-quality web development and SEO services." />
      </Helmet>
      <h1>About LLT Solutions</h1>
      <p>LLT Solutions is a veteran-owned web development and SEO company dedicated to helping businesses establish a strong online presence. Founded with a passion for technology and a commitment to excellence, we specialize in creating custom web solutions that drive results.</p>
      <h2>Our Mission</h2>
      <p>Our mission is to empower businesses with cutting-edge web technologies and SEO strategies, enabling them to thrive in the digital landscape. We believe in creating websites that not only look great but also perform exceptionally well in search engines and deliver an outstanding user experience.</p>
      <h2>Our Values</h2>
      <ul>
        <li>Innovation: We stay at the forefront of web technologies to deliver modern, efficient solutions.</li>
        <li>Integrity: We believe in transparency and honesty in all our client relationships.</li>
        <li>Quality: We're committed to delivering high-quality work that exceeds expectations.</li>
        <li>Customer Focus: Your success is our success. We work closely with you to understand and meet your unique needs.</li>
      </ul>
      <h2>Our Commitment to Animal Welfare</h2>
      <p>Beyond our technology services, we're passionate about making a difference in animal welfare. Learn more about our <Link to="/animal-fundraising">animal fundraising initiative</Link> and how we're working to support animal rescue efforts.</p>
      <Link to="/contact">Get in Touch with Us</Link>
    </PageContainer>
  );
};

export default About;