import React from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MetaTags from '../components/MetaTags';
import LoadingSpinner from '../components/LoadingSpinner';

const GET_POSTS = gql`
  query GetPosts {
    posts(first: 10) {
      nodes {
        id
        title
        date
        slug
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;

const BlogContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const PostGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const PostCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const PostImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const PostContent = styled.div`
  padding: 1rem;
`;

const PostTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const PostDate = styled.p`
  font-size: 0.9rem;
  color: #666;
`;

const Blog = () => {
  const { loading, error, data } = useQuery(GET_POSTS);

  if (loading) return <LoadingSpinner />;
  if (error) return <p>Error: {error.message}</p>;

  if (!data || !data.posts || !data.posts.nodes) {
    return <p>No posts found</p>;
  }

  return (
    <BlogContainer>
      <MetaTags
        title="Blog"
        description="Read the latest insights and updates from LLT Solutions"
        canonical="https://lltsolutions.dev/blog"
      />
      <h1>Our Blog</h1>
      {data.posts.nodes.length === 0 ? (
        <p>No posts available at the moment.</p>
      ) : (
        <PostGrid>
          {data.posts.nodes.map(post => (
            <PostCard key={post.id}>
              <Link to={`/blog/${post.slug}`}>
                {post.featuredImage && (
                  <PostImage src={post.featuredImage.node.sourceUrl} alt={post.title} />
                )}
                <PostContent>
                  <PostTitle>{post.title}</PostTitle>
                  <PostDate>{new Date(post.date).toLocaleDateString()}</PostDate>
                  <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                </PostContent>
              </Link>
            </PostCard>
          ))}
        </PostGrid>
      )}
    </BlogContainer>
  );
};

export default Blog;