import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const SEOOptimization = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>SEO Optimization Services - LLT Solutions</title>
        <meta name="description" content="Boost your online visibility with our expert SEO optimization services. We help you rank higher and attract more targeted traffic." />
      </Helmet>
      <h1>SEO Optimization Services</h1>
      <p>At LLT Solutions, we understand the importance of being found online. Our SEO optimization services are designed to boost your website's visibility in search engine results, driving more targeted traffic to your site.</p>
      <h2>Our SEO Services Include:</h2>
      <ul>
        <li>Keyword Research and Strategy</li>
        <li>On-Page SEO Optimization</li>
        <li>Content Creation and Optimization</li>
        <li>Technical SEO Audits</li>
        <li>Link Building Strategies</li>
        <li>Local SEO for Businesses</li>
      </ul>
      <p>Whether you're looking to improve your existing website's SEO or starting from scratch, we have the expertise to help you achieve your goals.</p>
      <Link to="/contact">Contact Us for a Free SEO Consultation</Link>
    </PageContainer>
  );
};

export default SEOOptimization;