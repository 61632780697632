import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ 
  title, 
  description, 
  canonical, 
  ogImage, 
  ogType = 'website',
  twitterCard = 'summary_large_image'
}) => (
  <Helmet>
    <title>{title} | LLT Solutions</title>
    <meta name="description" content={description} />
    {canonical && <link rel="canonical" href={canonical} />}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content={ogType} />
    {ogImage && <meta property="og:image" content={ogImage} />}
    <meta name="twitter:card" content={twitterCard} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    {ogImage && <meta name="twitter:image" content={ogImage} />}
  </Helmet>
);

export default MetaTags;