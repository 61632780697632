import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ContactContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 150px;
  font-size: 1rem;
`;

const Button = styled(motion.button)`
  padding: 0.75rem 1.5rem;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  align-self: flex-start;
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    businessType: '',
    currentWebsite: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., send to backend or email service)
    console.log(formData);
  };

  return (
    <ContactContainer>
      <Helmet>
        <title>Contact LLT Solutions - Web Development & SEO Services</title>
        <meta name="description" content="Get in touch with LLT Solutions for web development, SEO services, and more. We're here to help elevate your online presence." />
      </Helmet>
      <h1>Contact Us</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">Name</Label>
          <Input 
            type="text" 
            id="name"
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input 
            type="email" 
            id="email"
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="phone">Phone Number</Label>
          <Input 
            type="tel" 
            id="phone"
            name="phone" 
            value={formData.phone} 
            onChange={handleChange} 
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="businessType">Business Type</Label>
          <Select 
            id="businessType"
            name="businessType" 
            value={formData.businessType} 
            onChange={handleChange}
          >
            <option value="">Select Business Type</option>
            <option value="smallBusiness">Small Business</option>
            <option value="enterprise">Enterprise</option>
            <option value="nonprofit">Non-Profit</option>
            <option value="other">Other</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="currentWebsite">Current Website (if any)</Label>
          <Input 
            type="url" 
            id="currentWebsite"
            name="currentWebsite" 
            value={formData.currentWebsite} 
            onChange={handleChange} 
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="message">How can we help you?</Label>
          <Textarea 
            id="message"
            name="message" 
            value={formData.message} 
            onChange={handleChange} 
            required 
          />
        </FormGroup>
        <Button 
          type="submit"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Send Message
        </Button>
      </Form>
    </ContactContainer>
  );
};

export default Contact;