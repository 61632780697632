// src/pages/StaticWebsites.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  color: #1a202c;
  margin-bottom: 1rem;
`;

const Content = styled(motion.div)`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FeatureItem = styled(motion.li)`
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;

  &::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #48bb78;
  }
`;

const CTAButton = styled(motion(Link))`
  display: inline-block;
  background-color: #4299e1;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3182ce;
  }
`;

const PricingSection = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const PriceCard = styled(motion.div)`
  background-color: #f7fafc;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
`;

const Price = styled.h3`
  font-size: 1.8rem;
  color: #2d3748;
  margin-bottom: 0.5rem;
`;

const PortfolioSection = styled.div`
  margin-top: 2rem;
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`;

const PortfolioItem = styled.div`
  background-color: #f7fafc;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
`;

const StaticWebsites = () => {
  return (
    <PageContainer>
      <Title
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Static Websites
      </Title>
      <Content
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <p>
          Our static websites are perfect for businesses looking for a fast,
          secure, and cost-effective web presence. Built with modern technologies,
          our static sites offer excellent performance and easy maintenance.
        </p>
        <h2>Features:</h2>
        <FeatureList>
          {['Lightning-fast load times', 'Improved security', 'Cost-effective hosting', 'Easy to maintain', 'SEO-friendly structure'].map((feature, index) => (
            <FeatureItem
              key={feature}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              {feature}
            </FeatureItem>
          ))}
        </FeatureList>
        <PricingSection>
          <PriceCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <Price>$999</Price>
            <p>Includes:</p>
            <ul>
              <li>Up to 5 pages</li>
              <li>Responsive design</li>
              <li>Basic SEO optimization</li>
              <li>Square integration for bookings (plug-and-play)</li>
            </ul>
          </PriceCard>
          <PriceCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Price>$1,200</Price>
            <p>Includes everything in the $999 package, plus:</p>
            <ul>
              <li>Newsletter integration (e.g., Mailchimp)</li>
              <li>Blog setup</li>
              <li>Up to 10 pages</li>
            </ul>
          </PriceCard>
          <PriceCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <Price>Enterprise</Price>
            <p>For large projects with custom requirements</p>
            <CTAButton
              to="/contact"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Contact for Quote
            </CTAButton>
          </PriceCard>
        </PricingSection>
        <p>
          Note: Prices are for website development only. For ongoing management and support, please contact us for managed service options.
        </p>
        <PortfolioSection>
          <h2>Some of Our Work</h2>
          <PortfolioGrid>
            {['Project 1', 'Project 2', 'Project 3'].map((project, index) => (
              <PortfolioItem key={index}>
                <h3>{project}</h3>
                <p>Brief description of the project</p>
              </PortfolioItem>
            ))}
          </PortfolioGrid>
        </PortfolioSection>
        <CTAButton
          to="/contact"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Get a Quote
        </CTAButton>
      </Content>
    </PageContainer>
  );
};

export default StaticWebsites;