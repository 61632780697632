import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const CategoryList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CategoryItem = styled.li`
  margin-bottom: 1rem;
`;

const CategoryLink = styled(Link)`
  color: #333;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;

  &:hover {
    color: #4299e1;
  }
`;

const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
      nodes {
        id
        name
        slug
        count
      }
    }
  }
`;

const BlogCategories = () => {
  const { loading, error, data } = useQuery(GET_CATEGORIES);

  if (loading) return <p>Loading categories...</p>;
  if (error) return <p>Error loading categories: {error.message}</p>;

  return (
    <PageContainer>
      <Helmet>
        <title>Blog Categories - LLT Solutions</title>
        <meta name="description" content="Explore our blog categories covering web development, SEO tips, industry news, and more. Find valuable insights to help grow your online presence." />
      </Helmet>
      <h1>Blog Categories</h1>
      <CategoryList>
        {data.categories.nodes.map((category) => (
          <CategoryItem key={category.id}>
            <CategoryLink to={`/blog/category/${category.slug}`}>
              {category.name} ({category.count})
            </CategoryLink>
          </CategoryItem>
        ))}
      </CategoryList>
    </PageContainer>
  );
};

export default BlogCategories;