// src/components/Button.js
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const StyledButton = styled(motion.button)`
  background-color: #4299e1;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3182ce;
  }
`;

const Button = ({ children, to, onClick, ...props }) => {
  if (to) {
    return (
      <Link to={to}>
        <StyledButton as="span" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} {...props}>
          {children}
        </StyledButton>
      </Link>
    );
  }
  return (
    <StyledButton onClick={onClick} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;