import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import AnimalFundraisingPricing from '../components/AnimalFundraisingPricing';
import AnimalCard from '../components/AnimalCard';

// Import images
import jonesImage from '../assets/images/animals/jones.jpg';
import gingerImage from '../assets/images/animals/ginger.jpg';
import louieImage from '../assets/images/animals/louie.jpg';
import koaImage from '../assets/images/animals/koa.jpg';
import sunshineImage from '../assets/images/animals/sunshine.jpg';
import jessieImage from '../assets/images/animals/jessie.jpg';
import rustyImage from '../assets/images/animals/rusty.jpg';
import heisenbergImage from '../assets/images/animals/heisenberg.jpg';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const AnimalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const DonateButton = styled(motion(Link))`
  display: inline-block;
  background-color: #48bb78;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.1rem;
  margin-top: 2rem;
`;

const AnimalFundraising = () => {
  const animals = [
    { name: 'Jones', breed: 'Golden Retriever / Yellow Lab Mix', image: jonesImage },
    { name: 'Ginger', breed: 'Australian Shepherd and Husky Mix', image: gingerImage },    
    { name: 'Louie', breed: 'Terrier Mix', image: louieImage },
    { name: 'Koa', breed: 'German and Australian Shepherd mix', image: koaImage },
    { name: 'Sunshine', breed: 'Orange Shorthaired Tabby', image: sunshineImage },
    { name: 'Jessie', breed: 'Calico cat', image: jessieImage },
    { name: 'Rusty', breed: 'Calico cat', image: rustyImage },
    { name: 'Heisenberg', breed: 'Calico cat', image: heisenbergImage },
  ];

  return (
    <PageContainer>
      <Helmet>
        <title>Animal Fundraising - LLT Solutions</title>
        <meta name="description" content="Help support our rescued animals. Learn about our mission to care for animals in need and contribute to their medical care and well-being." />
      </Helmet>
      <h1>Our Animal Rescue Mission</h1>
      <p>At LLT Solutions, we're not just passionate about technology - we're also dedicated animal lovers. We've rescued 8 wonderful animals, all of whom have had emergencies, and some who still need surgeries.</p>
      <h2>Meet Our Furry Family</h2>
      <AnimalGrid>
        {animals.map((animal) => (
          <AnimalCard key={animal.name} animal={animal} />
        ))}
      </AnimalGrid>
      <h2>Our Mission</h2>
      <p>Our goal is to provide the best care possible for these animals and to eventually open a rescue. In the future, we aim to establish a non-profit fund to help people pay for their animal surgeries.</p>
      <AnimalFundraisingPricing />
      <h2>How You Can Help</h2>
      <p>Your donations go directly to veterinary bills, food, and treats to keep our animals happy and healthy. While we're not a non-profit yet (so donations aren't tax-deductible), every contribution makes a real difference in these animals' lives.</p>
      <DonateButton 
        to="/donate"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Donate Now
      </DonateButton>
    </PageContainer>
  );
};

export default AnimalFundraising;