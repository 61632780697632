import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FeatureItem = styled(motion.li)`
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;

  &::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #48bb78;
  }
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background-color: #4299e1;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3182ce;
  }
`;

const SEOKeywordApp = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>SEOKeyword App - Revolutionize Your SEO Strategy</title>
        <meta name="description" content="Discover our upcoming SEOKeyword App. Streamline your keyword research, optimize your content, and boost your search engine rankings effortlessly." />
      </Helmet>
      <h1>Introducing SEOKeyword App</h1>
      <p>SEOKeyword App is our upcoming tool designed to revolutionize your SEO strategy. With powerful features and an intuitive interface, it's the perfect solution for businesses looking to improve their search engine rankings.</p>
      <h2>Key Features:</h2>
      <FeatureList>
        {[
          'Advanced keyword research and analysis',
          'Real-time SEO recommendations',
          'Content optimization tools',
          'Competitor analysis',
          'Rank tracking and reporting',
          'Integration with popular CMS platforms'
        ].map((feature, index) => (
          <FeatureItem
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            {feature}
          </FeatureItem>
        ))}
      </FeatureList>
      <h2>Coming Soon</h2>
      <p>SEOKeyword App is currently in development. Sign up for our newsletter to be notified when it launches and get exclusive early access!</p>
      <CTAButton to="/contact">Get Notified</CTAButton>
    </PageContainer>
  );
};

export default SEOKeywordApp;