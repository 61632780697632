import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import TestimonialSlider from '../components/TestimonialSlider';
import CTA from '../components/CTA';

const HomeContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Hero = styled.div`
  text-align: center;
  padding: 4rem 0;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  border-radius: 10px;
  margin-bottom: 4rem;
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const Section = styled.section`
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const Card = styled(motion.div)`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Home = () => {
  const services = ['Static Websites', 'WordPress Development', 'Custom Web Applications', 'SEO Optimization'];

  return (
    <>
      <Helmet>
        <title>LLT Solutions - Modern Web Development & SEO Services</title>
        <meta name="description" content="LLT Solutions offers cutting-edge web development and SEO services, including our upcoming SEOKeyword App. We create modern, efficient, and SEO-friendly websites." />
        <meta name="keywords" content="web development, SEO, SEOKeyword App, modern websites, tech solutions" />
      </Helmet>
      <HomeContainer>
        <Hero>
          <Title
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Welcome to LLT Solutions
          </Title>
          <Subtitle
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Elevating Your Web Presence with Modern Tech & SEO
          </Subtitle>
          <Button as={Link} to="/contact">Get Started</Button>
        </Hero>

        <Section>
          <SectionTitle>Our Services</SectionTitle>
          <Grid>
            {services.map((service, index) => (
              <Card
                key={service}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <h3>{service}</h3>
                <p>Tailored solutions to enhance your online presence and drive results.</p>
              </Card>
            ))}
          </Grid>
        </Section>

        <Section>
          <SectionTitle>Introducing SEOKeyword App</SectionTitle>
          <Card>
            <h3>The Future of SEO is Here</h3>
            <p>Our upcoming SEOKeyword App will revolutionize how you approach search engine optimization. Stay tuned for a tool that will streamline your SEO efforts and boost your online visibility.</p>
            <Button as={Link} to="/seo-keyword-app">Learn More</Button>
          </Card>
        </Section>

        <Section>
          <SectionTitle>Why Choose LLT Solutions?</SectionTitle>
          <Grid>
            {[
              { title: 'Cutting-Edge Technology', desc: 'We leverage the latest web technologies to build fast, secure, and scalable solutions.' },
              { title: 'SEO-First Approach', desc: 'Every project is optimized for search engines from the ground up.' },
              { title: 'Custom Solutions', desc: 'Tailored web development to meet your unique business needs.' },
              { title: 'Ongoing Support', desc: 'Were with you every step of the way, from development to maintenance.' }
            ].map((item, index) => (
              <Card
                key={item.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
              </Card>
            ))}
          </Grid>
        </Section>
        <Section>
          <SectionTitle>What Our Clients Say</SectionTitle>
          <TestimonialSlider />
        </Section>

<CTA
  title="Ready to Get Started?"
  description="Let's work together to create your perfect web solution."
  buttonText="Contact Us"
  buttonLink="/contact"
/>
      </HomeContainer>
    </>
  );
};

export default Home;