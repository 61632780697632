// src/components/CTA.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const CTAContainer = styled.div`
  background-color: #4299e1;
  color: white;
  padding: 3rem;
  text-align: center;
  border-radius: 8px;
`;

const CTATitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const CTADescription = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled(motion(Link))`
  display: inline-block;
  background-color: white;
  color: #4299e1;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f4f8;
  }
`;

const CTA = ({ title, description, buttonText, buttonLink }) => {
  return (
    <CTAContainer>
      <CTATitle>{title}</CTATitle>
      <CTADescription>{description}</CTADescription>
      <CTAButton
        to={buttonLink}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {buttonText}
      </CTAButton>
    </CTAContainer>
  );
};

export default CTA;