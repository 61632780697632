import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #1a202c;
  color: white;
  padding: 2rem 0;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterSection = styled.div`
  margin-bottom: 1rem;
`;

const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ExternalLink = styled.a`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const PoweredBy = styled.div`
  text-align: center;
  margin-top: 2rem;
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>LLT Solutions</h3>
          <p>Your partner in modern web solutions</p>
        </FooterSection>
        <FooterSection>
          <h4>Quick Links</h4>
          <ul>
            <li><FooterLink to="/">Home</FooterLink></li>
            <li><FooterLink to="/about">About</FooterLink></li>
            <li><FooterLink to="/services">Services</FooterLink></li>
            <li><FooterLink to="/portfolio">Portfolio</FooterLink></li>
            <li><FooterLink to="/blog">Blog</FooterLink></li>
            <li><FooterLink to="/contact">Contact</FooterLink></li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h4>Services</h4>
          <ul>
            <li><FooterLink to="/services/static-websites">Static Websites</FooterLink></li>
            <li><FooterLink to="/services/wordpress-websites">WordPress Development</FooterLink></li>
            <li><FooterLink to="/services/seo-optimization">SEO Optimization</FooterLink></li>
            <li><FooterLink to="/services/web-development">Custom Web Development</FooterLink></li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h4>Connect</h4>
          <ul>
            <li><ExternalLink href="https://facebook.com/lltsolutions" target="_blank" rel="noopener noreferrer">Facebook</ExternalLink></li>
            <li><ExternalLink href="https://twitter.com/lltsolutions" target="_blank" rel="noopener noreferrer">Twitter</ExternalLink></li>
            <li><ExternalLink href="https://linkedin.com/company/lltsolutions" target="_blank" rel="noopener noreferrer">LinkedIn</ExternalLink></li>
          </ul>
        </FooterSection>
      </FooterContent>
      <PoweredBy>
        LLT Solutions | Powered by <ExternalLink href="https://loslunastechnology.com" target="_blank" rel="noopener noreferrer">Los Lunas Technology</ExternalLink>
      </PoweredBy>
    </FooterContainer>
  );
};

export default Footer;